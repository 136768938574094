import React, { } from 'react';
import styled from 'styled-components';

import Header from 'components/layout/header/header';
import MainMenuDrawer from 'components/layout/mainMenuDrawer/mainMenuDrawer'

import theme, { bp, color } from 'settings/theme';
import { Category } from '@prisma/client';
import Modal from 'components/common/modal/modal';
import useLayout from 'providers/layout/layoutProvider';
import HelpModal from 'components/common/modal/helpModal';
import Footer from './footer/footer';


type Props = {
  templateData: TemplateData,
  children?: React.ReactNode
};

// Component
const ShopLayout: React.FC<Props> = (props) => {

  const { isHelpModalOpen, setIsHelpModalOpen } = useLayout();
  // Handle size chart  modal open change
  const handleIsHelpModalOpenChange = (val) => {
    setIsHelpModalOpen(val);
  }
  return (
    <StyledLayout className="shopLayout">

      <div className="shopLayout-mainMenuDrawer">
        <MainMenuDrawer topLevelCategories={props.templateData?.topLevelCategories.find((category) => category.slug === "all-products")} />
      </div>

      <div className="shopLayout-header">
        <Header />
      </div>

      <div className="shopLayout-main">
        <div className="shopLayout-page">
          {props.children}
        </div>

        <div className="shopLayout-footer">
          <Footer />
        </div>
      </div>

      <Modal isOpen={isHelpModalOpen} setIsOpen={handleIsHelpModalOpenChange}>
        <HelpModal isOpen={isHelpModalOpen} setIsOpen={handleIsHelpModalOpenChange} />
      </Modal>
    </StyledLayout>
  );
};

export default ShopLayout;



const StyledLayout = styled.div`
  --header-height: 3.5rem;

  overflow-x: hidden;
  width: 100%;

  .shopLayout-mainMenuDrawer{
    z-index: 2;
    flex: 0 0 auto;
    width: 0;
  }

  .shopLayout-header{
    position: relative;
    z-index: 1;
  }

  .shopLayout-main{
    display: flex;
    flex-direction: column;
    min-height: calc(100svh - var(--header-height));
    min-height: calc(100vh - var(--header-height));
  }

  .shopLayout-page{
    flex: 1 1 auto;
    position: relative;
    z-index: 0;
  }

  .shopLayout-footer{
    flex: 0 0 auto;
  }

  @media (min-width: ${bp(3)}) {
    --header-height: 5rem;
  }

`;