import { Fragment, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import styled, { css, createGlobalStyle } from 'styled-components';
import { AnimatePresence, filterProps, motion } from 'framer-motion';

import theme, {bp, color} from 'settings/theme';

type Props = {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  children?: React.ReactNode
}

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
}

const variants2 = {
  visible: { opacity: 1, translateY: 0 },
  hidden: { opacity: 0, translateY: 30 }
}

const Modal: React.FC<Props> = ({isOpen, setIsOpen, children}) => {
    

  return (
    <StyledModal className="modal">

      <AnimatePresence>
        {isOpen &&
          <Dialog
            open={true}
            onClose={() => setIsOpen(false)}
            className={"modal-dialog"}
          >

            <motion.div
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{duration: 0.15}}
            >

              {/* Backdrop */}
              <StyledModalBackdrop className="modalBackdrop" onClick={() => setIsOpen(!isOpen)} />

              {/* Panel */}
              <StyledModalPanel className="modalWrapper" onClick={() => setIsOpen(!isOpen)}>
                <div className="modalInner">
                  <motion.div
                    variants={variants2}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={{type: 'spring', duration: 0.3}}
                    className="modalContent"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {children}
                  </motion.div>
                </div>
              </StyledModalPanel>

            </motion.div>

          </Dialog>
        }
        
      </AnimatePresence>
    </StyledModal>
  );
};

export default Modal;


const StyledModal = styled.div``

const StyledModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.60);
  backdrop-filter: blur(5px);
`

const StyledModalPanel = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;

  .modalInner{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }

  .modalContent{
    width: 100%;
    max-width: min(calc(100% - 3rem), 800px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    background-color: white;
  }


`
