import { useMemo, useEffect } from "react";
import { trpc } from 'utils/trpc';
import styled from "styled-components";

import theme, { bp, color } from 'settings/theme';
import SimpleButton from "../buttons/simpleButton";
import Image from "next/image";
import Link from "next/link";
type Props = {
  isOpen: boolean,
  setIsOpen: (val: boolean) => void,
};

const HelpModal: React.FC<Props> = (props) => {

  // Create chart
  const handleClose = () => {
    props.setIsOpen(!props.isOpen);
  }

  return (
    <StyledHelpModal className="helpModal">

      <div className="helpModal-topSection">
        <div className="helpModal-closeButton">
          <button onClick={handleClose}><Image src="/images/exit-solid.svg" height="16" width="16" alt="X" /></button>
        </div>
      </div>

      <div className="helpModal-content">
        <p>
          We are currenly in BETA, if you have any problem in ordering, please say Hi to us on our Whatsapp
        </p>
        <SimpleButton fullWidth={true} buttonStyle="secondary" onClick={handleClose}>
          <Link href={"https://chat.whatsapp.com/JeJmiaLSTmhEQHWtGfQFFe"} target="_blank">
            <span>Chat Now&ensp;</span><Image src="/images/whatsapp-white.svg" alt="Whatsapp Logo" height="24" width="24" />
          </Link>
        </SimpleButton>
      </div>

    </StyledHelpModal >
  );

};

export default HelpModal;



const StyledHelpModal = styled.div<{}>`

  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .helpModal-topSection{

    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;

    .helpModal-title{
      flex: 1;
      font-size: 1.125rem;
      font-weight: 600;
    }

    .helpModal-closeButton{

      flex: 0 0 auto;

      button{

        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 0.25rem;
        /* background-color: ${color.gray(25)}; */
        transition: background-color 0.15s ease-in-out;
        color: #000;
        

        &:hover{
          background-color: ${color.gray(35)};
        }

      }

    }
  }

  .helpModal-content{

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    p{
      margin-bottom: 2.5rem;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    div{
      width: 100%;
    }
  }


  @media (min-width: ${bp(3)}) {

  }

`

