import React, { } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';
import HeaderButtons from 'components/layout/header/headerButtons';

import theme, { bp, color } from 'settings/theme';

const Header: React.FC<{}> = (props) => {

  const layout = useLayout();


  // Handle menu button click
  const handleMenuClick = () => {
    layout.setIsMainMenuOpen(!layout.isMainMenuOpen);
  };

  const handleHelpClick = () => {
    layout.setIsHelpModalOpen(!layout.isHelpModalOpen);
  };

  return (
    <StyledHeader className="header">

      {/* Fake header for fixed positioning */}

      {/* Fixed header */}
      <div className="header-float">
        <MaxWidthWrapper>

          <div className="header-top-wrapper">
            <div className="header-main">
              <Link href="/">
                <img src="/images/eden-logo-white.svg" alt="The Eden Logo" />
              </Link>
            </div>

            <div className="header-right">

              {/* Buttons */}

              {/* Menu button */}
              <div className="header-menuButton">
                <button onClick={handleMenuClick} className="menuButton-ignore">
                  {layout.isMainMenuOpen ? (
                    <img src="/images/icons/icon-cross-white.svg" alt="Menu" style={{ transform: 'scale(0.9)' }} />
                  ) : (
                    <img src="/svg/hamburger-white.svg" alt="Menu" />
                  )}
                </button>
              </div>
            </div>


          </div>

        </MaxWidthWrapper>

      </div>

    </StyledHeader>
  );

};

export default Header;


// Header
const StyledHeader = styled.header`


  .header-float {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: var(--header-height);
    background-color: transparent;
    z-index: 1000; // Add a high z-index to ensure it appears above other elements
  }

  .header-top-wrapper{
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 3rem;
  }

  .header-banner-wrapper{
    height: 1.75rem;
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    background: #CDFF54;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125.5%;
    button{
      background: none;
      border: none;
      font-size: 1rem;

      color: black;
      text-decoration: underline;
      font-style: normal;
      font-weight: 400;
      line-height: 125.5%;
    }
    display: none;
  }

  .header-main {

    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    padding-left: 1rem;

    img{
      width: auto;
      height: 1.5rem;
    }
    
  }

  .header-right{
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }

  .header-menuButton{

    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 100%;

    button{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }

    img{
      width: 2.5rem;
      height: 1rem;
    }

  }

  .header-walletButton{
    display: none;
  }


  @media (min-width: ${bp(2)}) {
    .header-bagButton{
      right: 0;
    }
  }


  @media (min-width: ${bp(3)}) {
    
    .header-fake{
      height: var(--header-height);
    }

    .header-float{
      height: var(--header-height);
    }

    .header-top-wrapper{
      padding: 0 1.5rem;
      height: 100%;
    }

    .header-main{
      flex: 0 0 auto;
      justify-content: flex-start;
      padding:0;
    }

    .header-right{
      flex: 1 1 auto;
      width: auto;
    }

    .header-walletButton{
      display: flex;
      align-items: center; 
      margin-left: 1rem;
    }


  }

`