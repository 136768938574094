import React, { useMemo, useState } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import theme, { bp, color } from 'settings/theme';

type ButtonStyle = 'primary' | 'secondary' | 'ghost';

type Props = {
  as?: 'a' | 'link' | 'button',
  href?: string,
  target?: '_blank' | '_self' | '_parent' | '_top',
  fullWidth?: boolean,
  onClick?: (...any) => void,
  buttonStyle?: ButtonStyle,
  children?: React.ReactNode,
  replace?: boolean,
  shallow?: boolean,
  scroll?: boolean
};

// Component
const SimpleButton: React.FC<Props> = (props) => {

  const Wrapper = useMemo(() => {

    if (props.as === 'link') {
      const WrapperComponent: React.FC<{ children?: React.ReactNode }> = (innerProps) => (
        <Link
          href={props.href ?? '#'}
          replace={props.replace ?? false}
          shallow={props.shallow ?? false}
          scroll={props.scroll === undefined ? true : props.scroll}
        >
          {innerProps.children}
        </Link>
      );
      return WrapperComponent;
    }
    else if (props.as === 'a') {
      const WrapperComponent: React.FC<{ children?: React.ReactNode }> = (innerProps) => (
        <a href={props.href ?? '#'} target={props.target} onClick={props.onClick}>
          {innerProps.children}
        </a>
      );
      return WrapperComponent;
    }
    else {
      const WrapperComponent: React.FC<{ children?: React.ReactNode }> = (innerProps) => (
        <button type="button" onClick={props.onClick}>
          {innerProps.children}
        </button>
      );
      return WrapperComponent;
    }

  }, [props.as, props.as, props.href, props.target, props.onClick, props.replace, props.shallow]);

  return (
    <StyledLayout buttonStyle={props.buttonStyle} fullWidth={props.fullWidth ?? false} className="simpleButton">

      <Wrapper>
        {props.children}
      </Wrapper>

    </StyledLayout>
  );
};

SimpleButton.defaultProps = {
  as: 'button',
  buttonStyle: 'primary',
  fullWidth: false
};

export default SimpleButton;



const StyledLayout = styled.div<{ buttonStyle?: ButtonStyle, fullWidth: boolean }>`

  position: relative;

  button, a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.fullWidth ? '100%' : 'auto'};
    height: 2.5rem;
    padding: 0 2rem;
    color: black;
    font-size: 0.875rem;
    font-weight: 550;
    border-radius: 100px;
    background-color: white;
    transition: all 0.15s ease-out;
  }

  ${props => props.buttonStyle === 'primary' && css`
    button, a{

      color: white;
      background-color: ${color.green(50)};

      &:hover{
        background-color: ${color.green(60)};
      }

    }
  `}

  ${props => props.buttonStyle === 'secondary' && css`
    button, a{

      color: white;
      background-color: ${color.gray(25)};

      &:hover{
        background-color: ${color.gray(30)};
      }

    }
  `}

  ${props => props.buttonStyle === 'ghost' && css`
    button, a{
      
      color: ${color.gray(10)};
      background-color: ${color.gray(95)};

      &:hover{
        background-color: ${color.gray(98)};
      }

    }
  `}

  @media (min-width: ${bp(2)}) {

    button, a{
      height: 3rem;
      font-weight: 600;
    }

  }

`;
