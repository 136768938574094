import React, { ReactElement, useState, useMemo } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { Category } from '@prisma/client';

import MainMenuDrawerContainer from 'components/layout/mainMenuDrawer/mainMenuDrawerContainer';
import MainMenuDrawerItem from 'components/layout/mainMenuDrawer/mainMenuItem';
import { bp } from 'settings/theme';
import MaxWidthWrapper from '../maxWidthWrapper';
import Footer from '../footer/footer';

type Props = {
  topLevelCategories: Category
};

const MainMenuStyled: React.FC<Props> = (props) => {

  return (
    <MainMenuDrawerContainer>
      <MaxWidthWrapper className="mainMenuDrawer-topMenu">
        <StyledMainMenuDrawer className="mainMenuDrawer">

          <div className="mainMenuDrawer-topMenu">
            <div className='mainMenuDrawer-item'>
              <div className="mainMenuDrawer-topMenu-title">
                Indexes
              </div>
              <ul className="mainMenuDrawer-topMenu-items">
                <MainMenuDrawerItem
                  key={"Growers"}
                  text={"Grower Index"}
                  href={`/growers`}
                />
              </ul>
              <ul className="mainMenuDrawer-topMenu-items">
                <MainMenuDrawerItem
                  key={"Harvests"}
                  text={"Harvest Index"}
                  href={`/harvests`}
                />
              </ul>
            </div>

          </div>
          <Footer />

        </StyledMainMenuDrawer>
      </MaxWidthWrapper>

    </MainMenuDrawerContainer>
  );

};

export default MainMenuStyled;

const StyledMainMenuDrawer = styled.div<{}>`

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: var(--primary-primary-90, #201F1D);
  color: var(--primary-primary-0, #F7F2ED);
  .mainMenuDrawer-item{
    margin-bottom: 2rem;
  }
  .mainMenuDrawer-topMenu{

    flex: 1 1 auto;
    margin-top: 6rem;
    padding: 1rem 1rem 0 1rem;
    overflow-y: auto;

    .mainMenuDrawer-topMenu-title{
      font-size: 1.25rem;
      font-weight: 650;
      margin-bottom: 0.75rem;
    }

    .mainMenuDrawer-topMenu-items{
      display: flex;
      flex-direction: column;
      /* margin-top: 1rem; */
    }

  }

  .mainMenuDrawer-bottomMenu{

    flex: 0 0 auto;
    margin-top: 2rem;
    padding: 0 1rem;

    a{
      display: inline-block;
      height: 2rem;
      color: var(--primary-primary-20, #D9CCBF);
      
      font-size: 0.825rem;
      font-weight: 650;
      text-decoration: none;
    }

  }

  .mainMenuDrawer-buttons{
    flex: 0 0 auto;
    margin-top: 1.5rem;
    padding: 0 1rem 1rem 1rem;
  }

  @media (min-width: ${bp(2)}) {

    .mainMenuDrawer-topMenu{

      padding: 2rem 2rem 0 2rem;

      .mainMenuDrawer-topMenu-title{
        font-size: 1.5rem;
      }

    }

    .mainMenuDrawer-bottomMenu{

      padding: 0 2rem;

      a{
        height: 2rem;
        font-size: 0.875rem;
      }

    }

    .mainMenuDrawer-buttons{
      margin-top: 2rem;
      padding: 0 2rem 2rem 2rem;
    }

  }

  @media (min-width: ${bp(3)}) {

    .mainMenuDrawer-topMenu{
      margin-top: 5rem;
    }
}

`
