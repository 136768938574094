import React, { } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';

import theme, { bp, color } from 'settings/theme';


const Footer: React.FC<{}> = (props) => {

  const layout = useLayout();

  // Handle menu button click
  const handleMenuClick = () => {
    layout.setIsMainMenuOpen(!layout.isMainMenuOpen);
  };

  return (
    <StyledFooter className="footer">

      <div className="footer-wrapper">
        <div className="footer-layout">
          <div className="footer-left">
            <div className="footer-logo">
              <img src="/images/eden-logo-white.svg" alt="Eden Logo" />
            </div>
          </div>
          <div className="footer-right">
            <Link href="https://instagram.com/edenid.xyz?igshid=YTQwZjQ0NmI0OA==" target="_blank">
              <Image src="/svg/social-icons-instagram-mobile-white.svg" alt="Instagram" height="25" width="25" />
            </Link>
            <Link href="https://twitter.com/edenid_xyz" target="_blank">
              <Image src="/svg/social-icons-twitter-mobile-white.svg" alt="Twitter" height="25" width="25" />
            </Link>
            <Link href="https://youtube.com" target="_blank">
              <Image src="/svg/social-icons-youtube-mobile-white.svg" alt="Youtube" height="25" width="25" />
            </Link>
            <div />
            <Link href="https://www.linkedin.com/company/edenid-xyz/" target="_blank">
              <Image src="/svg/social-icons-linkedin-mobile-white.svg" alt="LinkedIn" height="25" width="25" />
            </Link>
            <Link href="https://gmail.com" target="_blank">
              <Image src="/svg/social-icons-mail-mobile-white.svg" alt="Email" height="25" width="25" />
            </Link>
          </div>
        </div>
      </div>

    </StyledFooter>
  );

};

export default Footer;


// Footer
const StyledFooter = styled.footer`
  width: 100%;

  .footer-layout{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.75rem;
  }

  .footer-left{
    flex: 1 1 auto;
  }

  .footer-right{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 0.75rem;
    a{
      padding: 0.5rem;
    }

  }

  .footer-logo{

    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    padding-left: 0.75rem;

    img{
      width: auto;
      height: 1.5rem;
    }
  }


  @media (min-width: ${bp(3)}) {

    .footer-layout{
      /* flex-direction: row; */
      /* padding: 1.5rem; */
      /* padding-bottom: 2rem; */
      justify-content: space-between;
    }

    .footer-menu{

      a{
        font-size: 1rem;
      }

    }

    .footer-copyright{
      margin-top: 0;
      font-size: 0.75rem;
      line-height: 1.2;
    }

    .footer-right{
      align-self: flex-end;
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }

  }

`