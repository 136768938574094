import React, { ReactElement, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import useClickOutside from 'hooks/useClickOutside';
// import { Scrollbars } from 'react-custom-scrollbars-2';

import useLayout from 'providers/layout/layoutProvider';
import theme, { bp, color } from 'settings/theme';


type Props = {
  children?: React.ReactNode
};

const MainMenuDrawerContainer: React.FC<Props> = (props) => {

  const layout = useLayout();
  const ref = useRef(null);

  useClickOutside(ref, () => {
    layout.setIsMainMenuOpen(false);
  }, 'menuButton-ignore');

  const handleBackgroundClick = () => {
    layout.setIsMainMenuOpen(false);
  }

  return (
    <StyledMainMenuDrawerContainer isOpen={layout.isMainMenuOpen} className="mainMenuDrawerContainer" ref={ref}>

      {/* Background */}
      <div className="mainMenuDrawerContainer-background" onClick={handleBackgroundClick}></div>

      {/* Content */}
      <div className="mainMenuDrawerContainer-menu">

        {props.children}

      </div>

    </StyledMainMenuDrawerContainer>
  );

};

export default MainMenuDrawerContainer;


const StyledMainMenuDrawerContainer = styled.div<{ isOpen: boolean }>`


  pointer-events: none;
  z-index: 1;
  position: fixed;
  top: 0rem;
  right: 0;
  bottom: 0;
  left: 0;

  .mainMenuDrawerContainer-background{

    pointer-events: ${props => props.isOpen ? 'all' : 'none'};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${props => props.isOpen ? '1' : '0'};
    transition: opacity 0.15s ease-out;
  }
  
  .mainMenuDrawerContainer-menu{
    z-index: 0;
    display: flex;
    pointer-events: ${props => props.isOpen ? 'all' : 'none'};
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: -1rem;
    left: 0;
    width: 100%;
    padding-bottom: 1rem;
    box-shadow: 2px 0 5px rgba(0,0,0,0.05);
    transition: transform 0.15s, opacity 0.15s;
    opacity: ${props => props.isOpen ? '1' : '0'};
    /* transform: translateY(${props => props.isOpen ? '0' : '0.25rem'}); */
    background-color: ${color.gray(95)};
  }

  @media (min-width: ${bp(4)}) {
    /* display: none; */
  }


`